import { useAuthStore } from '@shared-ui/auth/auth-store'
import { Button, Link, Loading, Spacer, Text } from '@shared-ui/components'
import { useRequest } from '@shared-ui/hooks'
import { doesCountrySupportUCare } from '@shared/lib'
import { validateShippingAddressInput } from '@shared/lib/validation/schemas'
import type { ShippingAddress } from '@shared/types/address'
import { useEffect } from 'react'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { SelectableShippingAddressPanel } from '../shipping-address-panel'
import { StepHeading } from '../step-heading'
import { SubmitContainer } from '../submit-container'
import { UCareGradient } from '../u-care'
import { UCareBadge } from '../u-care-badge'

export function SelectShippingAddress() {
  const {
    device,
    shippingAddresses,
    shippingAddressNew,
    shippingAddressId,
    isInvalidShippingAddress,
  } = useSubmitRmaStore()

  const { user } = useAuthStore()
  const [fetchAddresses, { loading }] = useRequest('userAddresses', {})

  useEffect(() => {
    async function doFetch() {
      const result = await fetchAddresses({})

      useSubmitRmaStore.setState({
        shippingAddresses: result.addresses,
        shippingAddressId: result.addresses.length === 1 ? result.addresses[0].id : 0,
      })
    }

    if (shippingAddresses) {
      return
    }

    void doFetch()
  }, [])

  const handleNext = async () => {
    try {
      let selectedAddress: ShippingAddress | null | undefined

      if (shippingAddressId === -1) {
        selectedAddress = shippingAddressNew
      } else {
        selectedAddress = (shippingAddresses || []).find((add) => add.id === shippingAddressId)
      }

      if (!selectedAddress) {
        throw new Error('Invalid shipping address')
      }

      await validateShippingAddressInput({
        address1: selectedAddress.address1 || '',
        city: selectedAddress.city || '',
        country: selectedAddress.country || '',
        email: user?.email || '',
        firstName: selectedAddress.firstName,
        lastName: selectedAddress.lastName,
        phoneNumber: selectedAddress.phoneNumber || '',
        state: selectedAddress.state || '',
        zipcode: selectedAddress.zipcode || '',
        companyName: selectedAddress.companyName,
      })

      useSubmitRmaStore.setState({
        shippingAddress: selectedAddress,
        isInvalidShippingAddress: false,
      })

      if (SubmitRmaService.isExternalAddressValidation()) {
        SubmitRmaService.setView('verifyShippingAddress')
      } else {
        SubmitRmaService.next()
      }
    } catch (err) {
      SubmitRmaService.setInvalidShippingAddress(true)
    }
  }

  const isUCare = !!device?.isUnifiCare
  const isLoading = loading || !shippingAddresses
  const haveAddress = (shippingAddresses && shippingAddresses.length > 0) || shippingAddressNew
  const isNextDisabled = isLoading || isInvalidShippingAddress || !haveAddress || !shippingAddressId

  return (
    <SubmitContainer>
      {isUCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column p-16 mt-6 gap-24 relative">
        <StepHeading heading="Select your shipping address" />

        {isLoading && <Loading />}

        {!isLoading && shippingAddresses && (
          <div className="flex column gap-8">
            {shippingAddressNew && (
              <SelectableShippingAddressPanel
                key=""
                disabled={false}
                shippingAddress={shippingAddressNew}
                selected={shippingAddressId === -1}
                onSelect={() => {
                  useSubmitRmaStore.setState({
                    shippingAddress: shippingAddressNew,
                    shippingAddressId: -1,
                    isInvalidShippingAddress: false,
                  })
                }}
              />
            )}

            {shippingAddresses.map((address) => (
              <SelectableShippingAddressPanel
                key={address.id}
                disabled={!!(isUCare && !doesCountrySupportUCare(address.country))}
                shippingAddress={address}
                selected={address.id === shippingAddressId}
                onSelect={() => {
                  useSubmitRmaStore.setState({
                    shippingAddress: address,
                    shippingAddressId: address.id,
                    isInvalidShippingAddress: false,
                  })
                }}
              />
            ))}

            {!shippingAddressNew && (
              <Link
                onClick={() => {
                  useSubmitRmaStore.setState({
                    shippingAddressEdit: shippingAddressNew,
                  })

                  SubmitRmaService.setView('addShippingAddress')
                }}
              >
                Add New Shipping Address
              </Link>
            )}
          </div>
        )}

        {isInvalidShippingAddress && (
          <div className="flex column">
            <Spacer />
            <Text color="danger">
              Your address is incomplete, please add a new one and fill out all the required fields.
            </Text>
          </div>
        )}

        <div className="flex justify-end gap-8">
          <Button variant="tertiary" onClick={SubmitRmaService.back} $minWidth="0px">
            Back
          </Button>

          <Button variant="primary" disabled={isNextDisabled} onClick={handleNext} $minWidth="0px">
            Next
          </Button>
        </div>
      </div>
    </SubmitContainer>
  )
}
